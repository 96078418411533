import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Footer } from "@obby/components";
import { AuthenticationPopup } from "../AuthenticationPopup";

import axios from "~/config/axios";
import AuthenticationState from "~/state/AuthenticationState";
import {
  userState,
  isCurrentUserTeacherState,
  schoolState
} from "../../atoms/global";
import { useSetRecoilState } from "recoil";
import { styles } from "./Layout.style";
import { ContactObbyPopup } from "../ContactObbyPopup";
import Script from "next/script";

export default function Layout(props) {
  const [userData, setUserData] = useState({
    user: false,
    school: false,
    isCurrentUserTeacher: false
  });
  const setGlobalUserData = useSetRecoilState(userState);
  const setIsCurrentUserTeacherState = useSetRecoilState(
    isCurrentUserTeacherState
  );
  const setGlobalSchoolState = useSetRecoilState(schoolState);
  useEffect(() => {
    async function fetchData() {
      let res = await axios.get("LoadMarketplaceUser");
      setUserData(res.data);
      AuthenticationState.events.start.next(res.data);
      setGlobalUserData(res.data.user);
      setIsCurrentUserTeacherState(res.data.isCurrentUserTeacher);
      setGlobalSchoolState(res.data.school);
    }
    fetchData();
  }, []);
  return (
    <div className={styles.layout()}>
      {/*<Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  `
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.Beacon('init', '705091ec-cadf-4d83-a16f-f43314a317cb')
  `
        }}
      />*/}
      {!props.noHeader && <Header {...props} {...userData} />}
      <div className={styles.content()}>{props.children}</div>
      <Footer />
      <AuthenticationPopup />
      <ContactObbyPopup />
    </div>
  );
}
